    // JavaScript code
    let isDragging = false;
    let startY = 0;
    let startObjectPositionY = 0;

    function handleMouseDown(event) {
      var body = document.getElementsByTagName("body")[0];
      event.preventDefault();
      isDragging = true;
      startY = event.clientY;
      const images = document.querySelectorAll('.banner-img');
      images.forEach(image => {
        // Beispiel: Hinzufügen einer Klasse
        let computedStyle = getComputedStyle(image);
        startObjectPositionY = parseFloat(computedStyle.objectPosition.split(' ')[1]);
        body.style.cursor = 'grabbing';
      });
      
    }

    function handleMouseMove(event) {
      event.preventDefault();
      if (!isDragging) return;
      const images = document.querySelectorAll('.banner-img');
      const currentY = event.clientY;
      const deltaY = currentY - startY;
      const objectPositionY = startObjectPositionY + deltaY * -0.1; // Adjust the sensitivity here
      if(objectPositionY < 0 || objectPositionY > 100) {

      } else {
        images.forEach(image => {
          image.style.objectPosition = `100% ${objectPositionY}%`;
        })
        
      }
      
    }

    function handleMouseUp(event) {
      event.preventDefault();
      var body = document.getElementsByTagName("body")[0];
      body.style.cursor = 'grab';
      isDragging = false;
    }

    // Attach event listeners
    document.addEventListener('DOMContentLoaded', function () {
      const container = document.querySelector('.container');
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    });