import './main.scss';
//import './bootstrap';
import './splitType';
import './drag';
import './carousel';
//import './gasp';





import Splide from '@splidejs/splide';
import {MyTransition}  from './splidejs.js'

var slider = new Splide('.splide', {
    type: 'fade',
    drag: true
})

slider.mount({});