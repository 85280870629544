
import SplitType from 'split-type'  // Function to animate the letters using SplitType

  // Function to animate the letters using SplitType
  function animateLetters(element, state) {
    const text = new SplitType(element);

    // Randomly offset the initial positions of the letters
    text.chars.forEach(function (letter) {
      var leftOffset = 0;
      

      if(state) {
        var topOffset = Math.random() * 30 - 10;
        letter.style.opacity = 0.4;
      } else {
        var topOffset = Math.random() * 100 - 50;
        letter.style.opacity = 0;
      }

      letter.style.transform = `translate(${leftOffset}px, ${topOffset}px)`;
    });

    // Trigger the animation by removing the transition properties
    text.chars.forEach(function (letter) {
      letter.style.transition = 'none';
      requestAnimationFrame(function () {
        letter.style.opacity = 1;
        letter.style.transform = 'translate(0, 0)';
        if(state) {
            letter.style.transition = 'opacity 1s ease, transform 0.8s ease';
        } else {
            letter.style.transition = 'opacity 1.9s ease, transform 0.9s ease';
        }
        
      });
    });

    if(state === 'hover') {
        // Remove the event listener temporarily
        element.removeEventListener('mouseenter', handleMouseEnter);

        // Add the event listener back after a delay of 4 seconds (4000 milliseconds)
        setTimeout(function () {
            element.addEventListener('mouseenter', handleMouseEnter);
        }, 1000);
    }
  }
  function handleMouseEnter() {
    animateLetters(this, 'hover');
  }
  // Call the animation function after the document is ready
  document.addEventListener('DOMContentLoaded', function () {
    const navItems = document.querySelectorAll('.nav-item');
    const splitHoverItems = document.querySelectorAll('.splitType');
    const splitTitle = document.getElementById('splitTitle');
    const splitSubTitle = document.getElementById('splitSubTitle');

    // splitSubTitle.addEventListener('mouseenter', handleMouseEnter);
    // animateLetters(splitTitle)
    navItems.forEach(function (navItem) {
        animateLetters(navItem)
        navItem.addEventListener('mouseenter', handleMouseEnter)
    });

    splitHoverItems.forEach(function (splitHoverItem) {
        animateLetters(splitHoverItem)
        splitHoverItem.addEventListener('mouseenter', handleMouseEnter)
    });
  });